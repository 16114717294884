import { Scene } from 'phaser';
import screenfull from 'screenfull';
import { HEIGHT, WIDTH } from '../constants/config';
import Player from '../objects/player';

class Control extends Scene {
  player!: Player;
  runIcon!: Phaser.GameObjects.Sprite;
  fullscreenEnter!: Phaser.GameObjects.Sprite;
  fullscreenOpened: boolean = false;

  constructor() {
    super({ key: 'control' });
  }

  create(data: { player: Player }) {
    this.player = data.player;

    this.runIcon = this.add
      .sprite(WIDTH - 30, HEIGHT - 30, 'run-icon')
      .setInteractive();

    this.fullscreenEnter = this.add
      .sprite(WIDTH - 30, 30, 'fullscreen-enter-icon')
      .setInteractive();

    const self = this;
    this.runIcon.on('pointerdown', function () {
      self.gearUp();
    });

    this.fullscreenEnter.on('pointerdown', function () {
      screenfull.request();
    });

    screenfull.onchange(() => {
      if (!screenfull.isFullscreen) {
        self.fullscreenEnter.setVisible(true);
      } else {
        self.fullscreenEnter.setVisible(false);
      }
    });
  }

  gearUp() {
    if (this.player.gearEnabled) {
      return;
    }

    this.player.gearEnabled = true;
    this.runIcon.setAlpha(0.3);

    setTimeout(() => {
      this.player.gearEnabled = false;
      this.runIcon.setAlpha(1);
    }, 3000);
  }
}

export default Control;

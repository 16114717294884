import { Scene } from 'phaser';
import TilesetAnimation from './tileset-animation';
import type { ICoor, IPosition, TScenes } from '../../shared';
import { FADE_DURATION } from '../constants/config';
import Player from '../objects/player';

class BaseScene extends Scene {
  key: TScenes;
  map!: Phaser.Tilemaps.Tilemap;
  tileset!: Phaser.Tilemaps.Tileset;
  player!: Player;
  transition!: boolean;
  withTSAnimation?: boolean;
  joystick: any;
  layers!: Phaser.Tilemaps.TilemapLayer[];
  isInteracting?: boolean;
  prevSceneKey?: TScenes;
  nextSceneKey?: TScenes;
  tilesetAnimation!: TilesetAnimation;
  keyboard!: {
    cursorKeys: Phaser.Types.Input.Keyboard.CursorKeys;
    isUp: () => boolean;
    isLeft: () => boolean;
    isDown: () => boolean;
    isRight: () => boolean;
  };

  constructor(key: TScenes) {
    super({ key });
    this.key = key;
  }

  init(position: IPosition) {
    console.log('init');
    this.scene.setVisible(false, this.key);
    this.layers = [];
    this.prevSceneKey = this.key;
    this.transition = true;
    this.input.keyboard!.removeAllListeners();

    if (!this.player) {
      this.player = new Player(this, this.key, position);
      console.log(this.player);

      const loadPlayer = async () => {
        await this.player.create(position);
      };

      loadPlayer();
    } else {
      this.player.scene = this;
      this.player.room = this.key;
      this.player.position = position;
      this.player.players = {};

      this.player.socket.emit('test', {});

      this.player.socket.emit('newPlayer', {
        room: this.key,
        position,
      });
    }
  }

  initKeyboard() {
    const cursorKeys = this.input.keyboard!.createCursorKeys();

    this.keyboard = {
      cursorKeys,
      isUp: () => {
        return this.joystick.up || cursorKeys.up.isDown;
      },
      isLeft: () => {
        return this.joystick.left || cursorKeys.left.isDown;
      },
      isDown: () => {
        return this.joystick.down || cursorKeys.down.isDown;
      },
      isRight: () => {
        return this.joystick.right || cursorKeys.right.isDown;
      },
    };
  }

  create(tilemap: string, tileset: string, withTSAnimation: boolean) {
    this.scene.run('control', {
      player: this.player,
    });
    this.withTSAnimation = withTSAnimation;
    this.map = this.add.tilemap(tilemap);
    this.tileset = this.map.addTilesetImage(tileset)!;

    for (let i = 0; i < this.map.layers.length; i++) {
      this.layers[i] = this.map.createLayer(
        this.map.layers[i].name,
        this.tileset,
        0,
        0,
      )!;
    }

    this.cameras.main.setBackgroundColor('#222');
    this.cameras.main.on('camerafadeincomplete', () => {
      console.log('camerafadeincomplete');
      this.transition = false;

      this.input.keyboard!.on('keyup', (event: { keyCode: number }) => {
        if (event.keyCode >= 37 && event.keyCode <= 40) {
          this.player.stop();
        }
      });

      this.registerCollision();
    });

    this.input.keyboard!.on('keydown', (event: { code: string }) => {
      if (event.code === 'Space') {
        this.player.gearUp();
      }
    });

    this.createJoystick();
    this.initKeyboard();
    this.cameras.main.on('camerafadeoutcomplete', this.changeScene.bind(this));
    console.log('create finished');
  }

  createJoystick() {
    this.input.addPointer(3);
    this.joystick = (this.plugins.get('virtualJoystick') as any).add(this, {
      x: 0,
      y: 0,
      radius: 36,
      base: this.add.circle(0, 0, 36, 0x888888, 0.6).setDepth(1),
      thumb: this.add.circle(0, 0, 18, 0xcccccc, 0.8).setDepth(1),
      dir: '8dir',
    });

    this.joystick.setVisible(false);

    this.input.on('pointerup', () => {
      if (!this.isInteracting) {
        this.joystick.setVisible(false);
      }
    });

    this.input.on('pointerdown', (pointer: ICoor) => {
      if (!this.isInteracting) {
        this.joystick.setPosition(pointer.x, pointer.y);
        this.joystick.update();
        this.joystick.setVisible(true);
      }
    });
  }

  update() {
    if (this.transition === false) {
      this.player.update({
        isUp: this.keyboard.isUp(),
        isDown: this.keyboard.isDown(),
        isLeft: this.keyboard.isLeft(),
        isRight: this.keyboard.isRight(),
        isUpRight: this.keyboard.isUp() && this.keyboard.isRight(),
        isUpLeft: this.keyboard.isUp() && this.keyboard.isLeft(),
        isDownRight: this.keyboard.isDown() && this.keyboard.isRight(),
        isDownLeft: this.keyboard.isDown() && this.keyboard.isLeft(),
      });
    }
  }

  onChangeScene() {
    this.transition = true;
    this.player.stop();
    this.cameras.main.fade(FADE_DURATION);
  }

  changeScene() {
    if (this.withTSAnimation) this.tilesetAnimation.destroy();

    this.player.changeScene(this.prevSceneKey!, this.nextSceneKey!);
    this.scene.start(this.nextSceneKey, {
      prevSceneKey: this.prevSceneKey,
      player: this.player,
    });
  }

  registerCollision() {
    throw new Error('registerCollision() not implemented');
  }

  registerTilesetAnimation(layer: Phaser.Tilemaps.TilemapLayer) {
    this.tilesetAnimation = new TilesetAnimation();
    this.tilesetAnimation.register(layer, this.tileset.tileData);
    this.tilesetAnimation.start();
  }

  getLayer(name: string): Phaser.Tilemaps.TilemapLayer | undefined {
    return this.layers.find((tilemapLayer) => {
      return tilemapLayer.layer.name === name;
    });
  }
}

export default BaseScene;

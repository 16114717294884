import Phaser, { Game } from 'phaser';
import VirtualJoystickPlugin from 'phaser3-rex-plugins/plugins/virtualjoystick-plugin';
import { HEIGHT, WIDTH } from './constants/config';
import scene from './scenes/index';

const canvas = document.getElementById('game-canvas') as HTMLCanvasElement;

const config = {
  type: Phaser.CANVAS,
  width: WIDTH,
  height: HEIGHT,
  canvas,
  physics: {
    default: 'arcade',
    arcade: {
      gravity: { y: 0 },
      fps: 60,
      debug: false,
    },
  },
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH,
  },
  plugins: {
    global: [
      {
        key: 'virtualJoystick',
        plugin: VirtualJoystickPlugin,
        start: true,
      },
    ],
  },
  scene: scene,
};

const game = new Game(config);

import { CustomEventListener } from '../../functions/types';
export class WebSocketWrap extends WebSocket {
  constructor(url: URL | string) {
    super(url);

    this.addEventListener('message', ({ data: dataJSON }) => {
      let data;

      try {
        data = JSON.parse(dataJSON);
      } catch (error) {
        console.log(
          "Failed to parse message, make sure you're passing a JSON object",
        );

        return;
      }

      this.dispatchEvent(
        new CustomEvent(data.type, {
          detail: data.payload,
        }),
      );
    });
  }

  emit(type: string, payload: Object): any {
    const parsedData = JSON.stringify({
      type,
      payload,
    });

    super.send(parsedData);

    return {
      type,
      payload,
    };
  }

  on(action: string, listener: CustomEventListener) {
    console.log('New Event Created: ' + action);
    this.addEventListener(action, listener as EventListener);
  }
}

export const NEW_PLAYER = 'newPlayer';
export const ALL_PLAYERS = 'allPlayers';
export const CHAT = 'chat';
export const KEY_PRESS = 'keyPress';
export const MOVE = 'move';
export const ASYNC_MOVE = 'asyncMove';
export const STOP = 'stop';
export const REMOVE = 'remove';
export const LOGIN = 'login';
export const LOG = 'log';
export const CHANGE_SCENE = 'changeScene';

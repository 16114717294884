import OutfitGroup from './OutfitGroup';
import {
  ALL_PLAYERS,
  ASYNC_MOVE,
  CHANGE_SCENE,
  CHAT,
  DOWN,
  IDLE_DOWN,
  IDLE_LEFT,
  IDLE_RIGHT,
  IDLE_UP,
  IPosition,
  KEY_PRESS,
  LEFT,
  LOG,
  LOGIN,
  MOVE,
  NEW_PLAYER,
  REMOVE,
  RIGHT,
  STOP,
  TDirection,
  TScenes,
  UP,
  WebSocketWrap,
} from '../../shared';
import {
  IMAGE_PLAYER_BASE,
  IMAGE_PLAYER_BODY,
  IMAGE_PLAYER_HAIR,
} from '../constants/assets';
import { FADE_DURATION } from '../constants/config';
import { FRAME_RATE, SPEED } from '../constants/player';
import BaseScene from '../utilities/base-scene';

interface IPlayer extends Phaser.Types.Physics.Arcade.SpriteWithDynamicBody {
  username?: Phaser.GameObjects.Text;
  outfit?: OutfitGroup;
}

interface Players {
  [key: string]: IPlayer;
}

interface PlayerState {
  [key: number]: any;
}

class Player {
  id?: string;
  scene: BaseScene;
  state: PlayerState = {};
  socket: WebSocketWrap;
  room: TScenes;
  position: IPosition;
  gearEnabled: boolean = false;
  players: Players;
  movimentState: string = IDLE_DOWN;

  constructor(scene: BaseScene, room: TScenes, position: IPosition) {
    this.scene = scene;
    this.room = room;
    this.position = position;
    this.socket = new WebSocketWrap(process.env.SOCKET_SERVER_URL!);
    this.players = {};
  }

  async socketReady() {
    return new Promise((resolve) => {
      this.socket.on('open', () => {
        resolve(true);
      });
    });
  }

  async create(position: IPosition) {
    await this.socketReady();

    this.socket.emit(NEW_PLAYER, {
      room: this.room,
      position,
    });

    this.socket.on(LOGIN, ({ detail }: CustomEvent) => {
      this.id = detail.id;
    });

    this.socket.on(LOG, ({ detail }: CustomEvent) => {
      console.log('SERVER LOG: ', detail);
    });

    this.socket.on(NEW_PLAYER, ({ detail }: CustomEvent) => {
      this.addPlayer(detail.id, detail.x, detail.y, detail.direction);
    });

    this.socket.on(ALL_PLAYERS, ({ detail }: CustomEvent) => {
      this.scene.cameras.main.fadeFrom(FADE_DURATION);
      this.scene.scene.setVisible(true, this.room);

      for (let i = 0; i < detail.length; i++) {
        this.addPlayer(
          detail[i].id,
          detail[i].x,
          detail[i].y,
          detail[i].direction,
        );
      }

      this.scene.physics.world.setBounds(
        0,
        0,
        this.scene.map.widthInPixels,
        this.scene.map.heightInPixels,
      );
      this.scene.cameras.main.setBounds(
        0,
        0,
        this.scene.map.widthInPixels,
        this.scene.map.heightInPixels,
      );

      this.scene.cameras.main.startFollow(this.players[this.id!], true);
      this.players[this.id!].setCollideWorldBounds(true);

      this.registerChat();
    });

    this.socket.on(MOVE, ({ detail }: CustomEvent) => {
      this.onMoved(detail);
    });

    this.socket.on(ASYNC_MOVE, ({ detail }: CustomEvent) => {
      const ping = this.scene.time.now - detail.id;
      const timeout = ping < FRAME_RATE ? 50 : 0;

      setTimeout(() => {
        delete this.state[detail.id];
        const missingStates = Object.getOwnPropertyNames(this.state);

        if (missingStates.length) return;

        this.onMoved(detail.player);
      }, timeout);
    });

    this.socket.on(STOP, ({ detail }: CustomEvent) => {
      // this.players[detail.id].x = detail.x;
      // this.players[detail.id].y = detail.y;

      // this.players[detail.id].outfit!.setXY(
      //   this.players[detail.id].x,
      //   this.players[detail.id].y,
      // );

      const currentDirection = this.players[detail.id].anims.currentAnim!.key;

      if (currentDirection.includes('up')) {
        this.players[detail.id].anims.play(IDLE_UP, true);
        this.players[detail.id].outfit!.playAnimation('idle-up');
      } else if (currentDirection.includes('right')) {
        this.players[detail.id].anims.play(IDLE_RIGHT, true);
        this.players[detail.id].outfit!.playAnimation('idle-right');
      } else if (currentDirection.includes('left')) {
        this.players[detail.id].anims.play(IDLE_LEFT, true);
        this.players[detail.id].outfit!.playAnimation('idle-left');
      } else if (currentDirection.includes('down')) {
        this.players[detail.id].anims.play(IDLE_DOWN, true);
        this.players[detail.id].outfit!.playAnimation('idle-down');
      }

      this.players[detail.id].anims.stop();
      this.players[detail.id].outfit!.stopAnimation();

      console.log(detail.id + ' STOPPED.');
    });

    this.socket.on(REMOVE, ({ detail }: CustomEvent) => {
      this.players[detail.id].destroy();
      this.players[detail.id].username!.destroy();
      this.players[detail.id].outfit!.destroyChildren();
      delete this.players[detail.id];
    });

    this.socket.on(CHANGE_SCENE, ({ detail }: CustomEvent) => {
      this.players[detail.id].destroy();
      this.players[detail.id].username!.destroy();
      this.players[detail.id].outfit!.destroyChildren();
      delete this.players[detail.id];
    });
  }

  changeScene(fromScene: TScenes, toScene: TScenes) {
    this.socket.emit(CHANGE_SCENE, {
      roomFrom: fromScene,
      roomTo: toScene,
    });
  }

  addPlayer(id: string, x: number, y: number, direction: TDirection) {
    this.players[id] = this.scene.physics.add
      .sprite(x, y, IMAGE_PLAYER_BASE)
      .setScale(0.8)
      .setAlpha(0);

    const outfit = new OutfitGroup(this.scene);
    outfit.add(this.scene.add.sprite(x, y, IMAGE_PLAYER_BASE).setScale(0.8));
    outfit.add(this.scene.add.sprite(x, y, IMAGE_PLAYER_HAIR).setScale(0.8));
    outfit.add(this.scene.add.sprite(x, y, IMAGE_PLAYER_BODY).setScale(0.8));

    this.players[id].body.setSize(28, 38);
    this.players[id].outfit = outfit;

    const depth = y + this.players[id].height / 2;

    this.players[id].outfit!.setDepth(depth);

    this.players[id].username = this.scene.add.text(x - 35, y - 45, id);
    this.players[id].username?.setDepth(depth + 2);
    this.players[id].anims.play(IDLE_DOWN);
    this.players[id].anims.stop();
  }

  left() {
    const player = this.players[this.id!];

    player.username!.x = player.x - 36;

    player.setVelocity(-this.getSpeed(), 0);

    player.anims.play(this.getMove(LEFT), true);
    player.outfit!.playAnimation(this.getOutfitMove('left'));

    player.outfit!.setXY(player.x, player.y);

    const depth = Math.ceil(player.y) + player.height / 2;
    player.outfit!.setDepth(depth);
    player.username!.setDepth(depth + 2);

    const id = this.scene.time.now;

    this.state[id] = this.socket.emit(KEY_PRESS, {
      id,
      room: this.room,
      position: {
        direction: this.getMove(LEFT),
        x: player.x,
        y: player.y,
      },
    });
  }

  right() {
    const player = this.players[this.id!];
    player.username!.x = player.x - 32;

    player.outfit!.setXY(player.x, player.y);

    player.setVelocity(this.getSpeed(), 0);

    player.anims.play(this.getMove(RIGHT), true);
    player.outfit!.playAnimation(this.getOutfitMove('right'));

    const depth = Math.ceil(player.y) + player.height / 2;
    player.outfit!.setDepth(depth);
    player.username!.setDepth(depth + 2);

    const id = this.scene.time.now;

    this.state[id] = this.socket.emit(KEY_PRESS, {
      id,
      room: this.room,
      position: {
        direction: this.getMove(RIGHT),
        x: player.x,
        y: player.y,
      },
    });
  }

  up() {
    const player = this.players[this.id!];
    player.username!.y = player.y - 46;

    player.outfit!.setXY(player.x, player.y);

    player.setVelocity(0, -this.getSpeed());
    player.anims.play(this.getMove(UP), true);
    player.outfit!.playAnimation(this.getOutfitMove('up'));

    const depth = Math.ceil(player.y) + player.height / 2;
    player.outfit!.setDepth(depth);
    player.username!.setDepth(depth + 2);

    const id = this.scene.time.now;

    this.state[id] = this.socket.emit(KEY_PRESS, {
      id,
      room: this.room,
      position: {
        direction: this.getMove(UP),
        x: player.x,
        y: player.y,
      },
    });
  }

  down() {
    const player = this.players[this.id!];
    player.username!.y = player.y - 43;

    player.setVelocity(0, this.getSpeed()).setDepth(player.y);
    player.anims.play(this.getMove(DOWN), true);
    player.outfit!.playAnimation(this.getOutfitMove('down'));

    player.outfit!.setXY(player.x, player.y);

    const depth = Math.ceil(player.y) + player.height / 2;
    player.outfit!.setDepth(depth);
    player.username!.setDepth(depth + 2);

    const id = this.scene.time.now;

    this.state[id] = this.socket.emit(KEY_PRESS, {
      id,
      room: this.room,
      position: {
        direction: this.getMove(DOWN),
        x: player.x,
        y: player.y,
      },
    });
  }

  upRight() {
    const player = this.players[this.id!];
    player.username!.y = player.y - 46;
    player.username!.x = player.x - 32;

    player
      .setVelocity(this.getSpeed() / 2, -this.getSpeed() / 2)
      .setDepth(player.y);
    player.anims.play(this.getMove(RIGHT), true);
    player.outfit!.playAnimation(this.getOutfitMove('right'));

    player.outfit!.setXY(player.x, player.y);

    const depth = Math.ceil(player.y) + player.height / 2;
    player.outfit!.setDepth(depth);
    player.username!.setDepth(depth + 2);

    const id = this.scene.time.now;

    this.state[id] = this.socket.emit(KEY_PRESS, {
      id,
      room: this.room,
      position: {
        direction: this.getMove(RIGHT),
        x: player.x,
        y: player.y,
      },
    });
  }

  upLeft() {
    const player = this.players[this.id!];

    player.username!.y = player.y - 46;
    player.username!.x = player.x - 36;

    player.outfit!.setXY(player.x, player.y);

    player
      .setVelocity(-this.getSpeed() / 2, -this.getSpeed() / 2)
      .setDepth(player.y);

    player.anims.play(this.getMove(LEFT), true);
    player.outfit!.playAnimation(this.getOutfitMove('left'));

    const depth = Math.ceil(player.y) + player.height / 2;
    player.outfit!.setDepth(depth);
    player.username!.setDepth(depth + 2);

    const id = this.scene.time.now;

    this.state[id] = this.socket.emit(KEY_PRESS, {
      id,
      room: this.room,
      position: {
        direction: this.getMove(LEFT),
        x: player.x,
        y: player.y,
      },
    });
  }

  downRight() {
    const player = this.players[this.id!];

    player.username!.y = player.y - 43;
    player.username!.x = player.x - 32;

    player.outfit!.setXY(player.x, player.y);

    player
      .setVelocity(this.getSpeed() / 2, this.getSpeed() / 2)
      .setDepth(player.y);
    player.anims.play(this.getMove(RIGHT), true);
    player.outfit!.playAnimation(this.getOutfitMove('right'));

    const depth = Math.ceil(player.y) + player.height / 2;
    player.outfit!.setDepth(depth);
    player.username!.setDepth(depth + 2);

    const id = this.scene.time.now;

    this.state[id] = this.socket.emit(KEY_PRESS, {
      id,
      room: this.room,
      position: {
        direction: this.getMove(RIGHT),
        x: player.x,
        y: player.y,
      },
    });
  }

  downLeft() {
    const player = this.players[this.id!];

    player.username!.y = player.y - 43;
    player.username!.x = player.x - 36;

    player.outfit!.setXY(player.x, player.y);

    player
      .setVelocity(-this.getSpeed() / 2, this.getSpeed() / 2)
      .setDepth(player.y);
    player.anims.play(this.getMove(LEFT), true);
    player.outfit!.playAnimation(this.getOutfitMove('left'));

    const depth = Math.ceil(player.y) + player.height / 2;
    player.outfit!.setDepth(depth);
    player.username!.setDepth(depth + 2);

    const id = this.scene.time.now;

    this.state[id] = this.socket.emit(KEY_PRESS, {
      id,
      room: this.room,
      position: {
        direction: this.getMove(LEFT),
        x: player.x,
        y: player.y,
      },
    });
  }

  stop() {
    const player = this.players[this.id!];

    if (player?.anims?.isPlaying) {
      console.log('keydown stop');
      player.setVelocity(0, 0);
      player.anims.stop();
      player.outfit!.stopAnimation();

      this.socket.emit(STOP, {
        room: this.room,
        x: player.x,
        y: player.y,
      });
    }
  }

  update(direction: {
    isUp: boolean;
    isUpRight: boolean;
    isUpLeft: boolean;
    isDownRight: boolean;
    isDownLeft: boolean;
    isLeft: boolean;
    isDown: boolean;
    isRight: boolean;
  }) {
    const {
      isUp,
      isDown,
      isLeft,
      isRight,
      isDownLeft,
      isDownRight,
      isUpLeft,
      isUpRight,
    } = direction;

    if (isUp && !isUpRight && !isUpLeft) {
      this.up();
    } else if (isDown && !isDownRight && !isDownLeft) {
      this.down();
    } else if (isDownRight) {
      this.downRight();
    } else if (isDownLeft) {
      this.downLeft();
    } else if (isUpRight) {
      this.upRight();
    } else if (isUpLeft) {
      this.upLeft();
    } else if (isLeft && !isUpLeft && !isDownLeft) {
      this.left();
    } else if (isRight && !isUpRight && !isDownRight) {
      this.right();
    } else {
      this.stop();
    }
  }

  registerChat() {
    // let chat = document.getElementById(CHAT);
    // let messages = document.getElementById('messages')!;
    // chat!.onsubmit = (e) => {
    //   e.preventDefault();
    //   let message = document.getElementById('message')! as HTMLInputElement;
    //   this.socket.emit(CHAT, message.value);
    //   message.value = '';
    //   const activeElement = document.activeElement! as HTMLElement;
    //   if (activeElement !== document.body) {
    //     activeElement.setAttribute('readonly', 'readonly');
    //     activeElement.setAttribute('disabled', 'true');
    //     setTimeout(function () {
    //       activeElement.blur();
    //       activeElement.removeAttribute('readonly');
    //       activeElement.removeAttribute('disabled');
    //     }, 100);
    //   }
    // };
    // this.socket.on(CHAT, (name, message) => {
    //   messages.innerHTML += `${name}: ${message}<br>`;
    //   messages.scrollTo(0, messages.scrollHeight);
    // });
  }

  gearUp() {
    if (this.gearEnabled) {
      return;
    }

    this.gearEnabled = true;

    setTimeout(() => {
      this.gearEnabled = false;
    }, 3000);
  }

  onMoved(data: any) {
    const player = this.players[data.id];

    player.x = data.x;
    player.y = data.y;

    player.outfit!.setXY(player.x, player.y);
    player.username!.x = data.x - 25;
    player.username!.y = data.y - 35;
    // player.anims.play(data.direction, true);

    player.outfit!.setDepth(Math.ceil(player.y) + player.height / 2);

    // player.outfit!.playAnimation(data.direction.replace('base-', ''));
  }

  getSpeed() {
    return this.gearEnabled ? SPEED * 2 : SPEED;
  }

  getMove(direction: string) {
    return this.gearEnabled ? direction.replace('walk', 'run') : direction;
  }

  getOutfitMove(direction: string) {
    return (this.gearEnabled ? 'run-' : 'walk-') + direction;
  }
}

export default Player;

import Phaser from 'phaser';

class OutfitGroup extends Phaser.GameObjects.Group {
  constructor(scene: Phaser.Scene) {
    super(scene);
  }

  playAnimation(key: string) {
    const children = this.getChildren() as Phaser.GameObjects.Sprite[];

    for (let index = 0; index < children.length; index++) {
      children[index].anims.play(children[index].texture.key + '-' + key, true);
    }

    return this;
  }

  stopAnimation() {
    const children = this.getChildren() as Phaser.GameObjects.Sprite[];

    for (let index = 0; index < children.length; index++) {
      children[index].anims.stop();
    }
  }

  destroyChildren() {
    const children = this.getChildren().filter(
      Boolean,
    ) as Phaser.GameObjects.Sprite[];

    for (let index = 0; index < children.length; index++) {
      children[index].destroy();
    }

    this.destroy();
  }
}

export default OutfitGroup;

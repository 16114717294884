export const UP = 'base-walk-up';
export const LEFT = 'base-walk-left';
export const DOWN = 'base-walk-down';
export const RIGHT = 'base-walk-right';

export const HAIR_UP = 'hair-walk-up';
export const HAIR_LEFT = 'hair-walk-left';
export const HAIR_DOWN = 'hair-walk-down';
export const HAIR_RIGHT = 'hair-walk-right';

export const IDLE_UP = 'base-idle-up';
export const IDLE_LEFT = 'base-idle-left';
export const IDLE_DOWN = 'base-idle-down';
export const IDLE_RIGHT = 'base-idle-right';

export const HAIR_IDLE_UP = 'hair-idle-up';
export const HAIR_IDLE_LEFT = 'hair-idle-left';
export const HAIR_IDLE_DOWN = 'hair-idle-down';
export const HAIR_IDLE_RIGHT = 'hair-idle-right';

export const RUN_UP = 'base-run-up';
export const RUN_LEFT = 'base-run-left';
export const RUN_DOWN = 'base-run-down';
export const RUN_RIGHT = 'base-run-right';

export const HAIR_RUN_UP = 'hair-run-up';
export const HAIR_RUN_LEFT = 'hair-run-left';
export const HAIR_RUN_DOWN = 'hair-run-down';
export const HAIR_RUN_RIGHT = 'hair-run-right';

// Types
export type TDirection = typeof UP | typeof LEFT | typeof DOWN | typeof RIGHT;

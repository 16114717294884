import { DOWN, HOUSE_1, HOUSE_2, IPosition, TOWN } from '../../shared';
import { IMAGE_TOWN, MAP_TOWN } from '../constants/assets';
import BaseScene from '../utilities/base-scene';

class Town extends BaseScene {
  constructor() {
    super(TOWN);
  }

  init(data: any) {
    console.log('Town: ', data);
    this.player = data.player;
    super.init(this.getPosition(data.prevSceneKey));
  }

  create() {
    super.create(MAP_TOWN, IMAGE_TOWN, false);
  }

  registerCollision() {
    const river = this.getLayer('river')!;
    const houses = this.getLayer('houses')!;
    const camps = this.getLayer('camps')!;
    const trees = this.getLayer('trees')!;
    const trees2 = this.getLayer('trees-2')!;
    trees2.setDepth(1);

    /* River */
    river.setCollisionBetween(0, 1021);

    /* House 1 */
    houses.setCollisionBetween(105, 110);
    houses.setCollisionBetween(125, 130);
    houses.setCollisionBetween(145, 150);
    houses.setCollisionBetween(165, 170);

    /* House 2 */
    houses.setCollisionBetween(207, 207);
    houses.setCollisionBetween(226, 228);
    houses.setCollisionBetween(245, 249);
    houses.setCollisionBetween(264, 270);
    houses.setCollisionBetween(284, 290);
    houses.setCollisionBetween(304, 310);
    houses.setCollisionBetween(324, 330);
    houses.setCollisionBetween(344, 350);
    houses.setCollisionBetween(1661, 1663);

    /* Camps */
    camps.setCollisionBetween(5, 25);

    trees.setCollisionByProperty({ collides: true });

    console.log('registerCollision');
    console.log(this.player);

    let player = this.player.players[this.player.id!];

    this.physics.add.collider(player, river);
    this.physics.add.collider(player, camps);
    this.physics.add.collider(player, trees);
    // this.physics.add.collider(player, trees2);
    this.physics.add.collider(player, houses, (sprite, tile: any) => {
      if (tile.index === 167) {
        this.nextSceneKey = HOUSE_1;
        this.onChangeScene();
      } else if (tile.index === 1661 || tile.index === 1662) {
        this.nextSceneKey = HOUSE_2;
        this.onChangeScene();
      }
    });

    // const debugGraphics = this.add.graphics().setAlpha(0.7);
    // river.renderDebug(debugGraphics, {
    //   tileColor: null,
    //   collidingTileColor: new Phaser.Display.Color(243, 234, 48, 255),
    //   faceColor: new Phaser.Display.Color(40, 39, 37, 255),
    // });
    // houses.renderDebug(debugGraphics, {
    //   tileColor: null,
    //   collidingTileColor: new Phaser.Display.Color(243, 234, 48, 255),
    //   faceColor: new Phaser.Display.Color(40, 39, 37, 255),
    // });
    // camps.renderDebug(debugGraphics, {
    //   tileColor: null,
    //   collidingTileColor: new Phaser.Display.Color(243, 234, 48, 255),
    //   faceColor: new Phaser.Display.Color(40, 39, 37, 255),
    // });
    // trees.renderDebug(debugGraphics, {
    //   tileColor: null,
    //   collidingTileColor: new Phaser.Display.Color(243, 234, 48, 255),
    //   faceColor: new Phaser.Display.Color(40, 39, 37, 255),
    // });
  }

  getPosition(data: IPosition | string): IPosition {
    console.log('getPosition', data);
    if (data === HOUSE_1 || !data) {
      return { x: 225, y: 280, direction: DOWN };
    } else if (data === HOUSE_2) {
      return { x: 655, y: 470, direction: DOWN };
    } else {
      return { x: 0, y: 0, direction: DOWN };
    }
  }
}

export default Town;

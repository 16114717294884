import { Scene } from 'phaser';
import { TOWN } from '../../shared';
import {
  IMAGE_HOUSE,
  IMAGE_PLAYER_BASE,
  IMAGE_PLAYER_BODY,
  IMAGE_PLAYER_HAIR,
  IMAGE_TOWN,
  MAP_HOUSE_1,
  MAP_HOUSE_2,
  MAP_TOWN,
} from '../constants/assets';
import { FRAME_RATE } from '../constants/player';
import { INIT } from '../constants/scenes';

class Init extends Scene {
  progressBar: Phaser.GameObjects.Graphics | null;
  progressCompleteRect: Phaser.Geom.Rectangle | null;
  progressRect: Phaser.Geom.Rectangle | null;
  music: Phaser.Sound.BaseSound | null;

  constructor() {
    super({ key: INIT });
    this.progressBar = null;
    this.progressCompleteRect = null;
    this.progressRect = null;
    this.music = null;
  }

  preload() {
    this.load.tilemapTiledJSON(MAP_TOWN, 'assets/maps/town.json');
    this.load.tilemapTiledJSON(MAP_HOUSE_1, 'assets/maps/house-1.json');
    this.load.tilemapTiledJSON(MAP_HOUSE_2, 'assets/maps/house-2.json');

    this.load.spritesheet(IMAGE_HOUSE, 'assets/maps/house.png', {
      frameWidth: 32,
      frameHeight: 32,
    });

    this.load.spritesheet(IMAGE_TOWN, 'assets/maps/town.png', {
      frameWidth: 32,
      frameHeight: 32,
    });

    this.load.image('run-icon', 'assets/sprites/icons/run-icon.png');
    this.load.image(
      'fullscreen-enter-icon',
      'assets/sprites/icons/fullscreen-enter.png',
    );

    this.load.atlas(
      IMAGE_PLAYER_BASE,
      'assets/sprites/player/base.png',
      'assets/sprites/player/base.json',
    );

    this.load.atlas(
      IMAGE_PLAYER_HAIR,
      'assets/sprites/player/hair.png',
      'assets/sprites/player/hair.json',
    );

    this.load.atlas(
      IMAGE_PLAYER_BODY,
      'assets/sprites/player/body.png',
      'assets/sprites/player/body.json',
    );

    this.load.audio('music-town', ['assets/music/town.mp3']);

    this.load.on('progress', this.onLoadProgress, this);
    this.load.on('complete', this.onLoadComplete, this);
    this.createProgressBar();
  }

  create() {
    this.createAnims();

    document
      .querySelector('canvas')!
      .addEventListener('pointerdown', function () {
        const activeElement = document.activeElement! as HTMLInputElement;

        if (document.activeElement !== document.body) {
          activeElement.setAttribute('readonly', 'readonly');
          activeElement.setAttribute('disabled', 'true');

          setTimeout(function () {
            activeElement.blur();
            activeElement.removeAttribute('readonly');
            activeElement.removeAttribute('disabled');
          }, 100);
        }
      });
  }

  createProgressBar() {
    let Rectangle = Phaser.Geom.Rectangle;
    let main = Rectangle.Clone(
      this.cameras.main as unknown as Phaser.Geom.Rectangle,
    );

    this.progressRect = new Rectangle(0, 0, main.width / 2, 50);
    Rectangle.CenterOn(this.progressRect, main.centerX, main.centerY);

    this.progressCompleteRect = Phaser.Geom.Rectangle.Clone(this.progressRect);

    this.progressBar = this.add.graphics();
  }

  onLoadComplete() {
    this.scene.start(TOWN);
  }

  onLoadProgress(progress: number) {
    let color = 0xffffff;

    this.progressRect!.width = progress * this.progressCompleteRect!.width;
    this.progressBar!.clear()
      .fillStyle(0x222222)
      .fillRectShape(this.progressCompleteRect!)
      .fillStyle(color)
      .fillRectShape(this.progressRect!);
  }

  createAnims() {
    const self = this;
    const types = ['base', 'hair', 'body'];

    const directions = [
      {
        direction: 'down',
        idleFrames: ['00'],
        walkFrames: [32, 33, 34, 35, 36, 37],
        runFrames: [32, 33, 38, 35, 36, 39],
      },
      {
        direction: 'up',
        idleFrames: ['08'],
        walkFrames: [40, 41, 42, 43, 44, 45],
        runFrames: [40, 41, 46, 43, 44, 47],
      },
      {
        direction: 'right',
        idleFrames: [16],
        walkFrames: [48, 49, 50, 51, 52, 53],
        runFrames: [48, 49, 54, 51, 52, 55],
      },
      {
        direction: 'left',
        idleFrames: [24],
        walkFrames: [56, 57, 58, 59, 60, 61],
        runFrames: [56, 57, 62, 59, 60, 63],
      },
    ];
    types.forEach((type) => {
      directions.forEach(({ direction, idleFrames, walkFrames, runFrames }) => {
        self.anims.create({
          key: type + '-idle-' + direction,
          frames: idleFrames.map((frame) => ({
            key: type,
            frame: frame + '.png',
          })),
        });

        this.anims.create({
          key: type + '-walk-' + direction,
          frames: walkFrames.map((frame) => ({
            key: type,
            frame: frame + '.png',
          })),
          repeat: -1,
          frameRate: FRAME_RATE,
        });

        this.anims.create({
          key: type + '-run-' + direction,
          frames: runFrames.map((frame) => ({
            key: type,
            frame: frame + '.png',
          })),
          repeat: -1,
          frameRate: FRAME_RATE,
        });
      });
    });
  }
}

export default Init;
